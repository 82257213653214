(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-player-forecast-from-subscription.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-player-forecast-from-subscription.js');
"use strict";

const _excluded = ["wagerId"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  usePreviousQuery
} = svs.components.lbUtils.usePrevious;
const {
  useDrawForecast,
  useGetCurrentWagerProductInfo
} = svs.components.tipsen.hooksWager;
const createPlayerForecastSubscription = (useProductDetailsFromWagerId, useDrawForecast) => function (_ref) {
  var _drawForecast$data, _drawForecast$data2, _drawForecast$data3;
  let {
    skip = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let {
      wagerId
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    productId,
    drawNumber
  } = useProductDetailsFromWagerId(_objectSpread({
    wagerId
  }, props));
  const drawForecast = useDrawForecast({
    productId,
    drawNumber,
    skip
  });
  const hasDrawForecast = drawForecast.currentData && drawForecast.data && Object.keys(drawForecast.data).length > 0;
  const shouldFetchPlayerForecast = hasDrawForecast && wagerId;

  const resultIdx = ((_drawForecast$data = drawForecast.data) === null || _drawForecast$data === void 0 ? void 0 : _drawForecast$data.ref_resultIdx) > 0 ? (_drawForecast$data2 = drawForecast.data) === null || _drawForecast$data2 === void 0 ? void 0 : _drawForecast$data2.ref_resultIdx : (_drawForecast$data3 = drawForecast.data) === null || _drawForecast$data3 === void 0 ? void 0 : _drawForecast$data3.resultIdx;
  const playerForecast = tipsenApi.useGetPlayerForecastQuery({
    productId,
    drawNumber,
    wagerId,
    resultIdx
  }, {
    skip: !shouldFetchPlayerForecast || skip
  });
  const cachePlayerForecast = usePreviousQuery(playerForecast);
  return cachePlayerForecast;
};
const useProductDetailsFromListWager = _ref2 => {
  let {
    productId,
    drawNumber
  } = _ref2;
  return React.useMemo(() => ({
    productId,
    drawNumber
  }), [productId, drawNumber]);
};
const useGetDrawForecastFromQuery = function (_ref3) {
  let {
    productId,
    drawNumber
  } = _ref3;
  let {
    skip = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return tipsenApi.useGetDrawForecastQuery({
    productId,
    drawNumber
  }, {
    skip
  });
};

const usePlayerForecastFromSubscription = createPlayerForecastSubscription(useGetCurrentWagerProductInfo, useDrawForecast);

const useWagerForecastSubscription = createPlayerForecastSubscription(useProductDetailsFromListWager, useGetDrawForecastFromQuery);
setGlobal('svs.components.tipsen.hooksWager.usePlayerForecastFromSubscription', usePlayerForecastFromSubscription);
setGlobal('svs.components.tipsen.hooksWager.useWagerForecastSubscription', useWagerForecastSubscription);

 })(window);