(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-sanitize-mybets-wager.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-sanitize-mybets-wager.js');
"use strict";

const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const {
  useHistory
} = ReactRouterDOM;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  useMemo
} = React;
const {
  useDialog
} = svs.components.lbUi.betterDialog;
const secondsPerDay = 86400;
const aYearInDays = 365;
const useIsDateTooOld = date => useMemo(() => {
  const secondsSince = Math.floor((new Date() - new Date(date)) / 1000);
  const interval = secondsSince / secondsPerDay;
  return Boolean(date && interval >= aYearInDays);
}, [date]);
const useSanitizeMybetsWager = wagerId => {
  const wagerTooOldDialog = useDialog({
    title: 'Detaljer för spel äldre än 12 månader kan tyvärr inte visas.',
    icon: 'exclamation-sign',
    acceptText: 'Tillbaka till lobbyn'
  });
  const history = useHistory();
  const definition = useDefinition();
  const wager = tipsenApi.useGetPlayerWagerQuery({
    wagerId
  });
  const registerWagerTime = wager.isSuccess ? wager.data.productBet.regWagerTime : undefined;
  const isTooOldGame = useIsDateTooOld(registerWagerTime);
  const productId = wager.isSuccess ? wager.data.productBet.productId : undefined;
  const definitionDrawProductIds = definition.drawProductIds;
  return useMemo(() => {
    const wagerTooOld = async () => {
      await wagerTooOldDialog.open();
      wagerTooOldDialog.close();
      history.replace("/".concat(TipsenModuleGameUrl.MY_BETS2));
    };
    if (wager.isSuccess && !definitionDrawProductIds.includes(productId)) {
      history.replace("/".concat(TipsenModuleGameUrl.MY_BETS2));
    } else if (wager.isSuccess && isTooOldGame) {
      wagerTooOld();
    } else if (wager.isSuccess && !isTooOldGame) {
      return true;
    }
    return false;
  }, [definitionDrawProductIds, history, isTooOldGame, productId, wager.isSuccess, wagerTooOldDialog]);
};
setGlobal('svs.components.tipsen.hooksWager.useSanitizeMybetsWager', useSanitizeMybetsWager);

 })(window);